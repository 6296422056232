"use client";

import * as React from "react";
import { useEffect, useState } from "react";

const TRADITIONAL_CONNECTORS = {
  line1: [
    "M37.19,130.69l.37.49h0c1.16-.87,2.3-1.75,3.38-2.63l-.39-.47c-1.07.88-2.2,1.76-3.35,2.61h-.01Z",
    "M43.76,125.29l.41.45c.57-.51,1.12-1.04,1.67-1.58.48-.47.94-.94,1.4-1.43l-.45-.42c-.45.48-.91.95-1.38,1.41-.54.53-1.09,1.05-1.65,1.56h0Z",
    "M49.57,119.11l.48.38h0c.88-1.11,1.73-2.27,2.54-3.46l-.51-.34c-.8,1.17-1.64,2.33-2.51,3.42Z",
    "M54.31,112.09l.53.3c.7-1.23,1.36-2.51,1.97-3.81l-.55-.26c-.61,1.29-1.27,2.56-1.95,3.77h0Z",
    "M57.94,104.41l.57.22c.51-1.32.98-2.69,1.4-4.05l-.58-.18c-.41,1.35-.88,2.7-1.38,4.01h-.01Z",
    "M60.42,96.31l.6.14h0c.32-1.39.6-2.81.82-4.21l-.61-.1c-.22,1.4-.5,2.8-.82,4.17h0Z",
    "M61.78,87.93l.61.07c.14-1.42.25-2.86.32-4.27l-.61-.03c-.07,1.4-.17,2.83-.31,4.24h-.01Z",
    "M62.78,79.44h-.61c-.02-1.44-.08-2.87-.18-4.23l.61-.05c.11,1.38.17,2.81.18,4.27h0Z",
    "M60.66,66.84c.33,1.3.62,2.7.84,4.15l.61-.09c-.23-1.47-.52-2.89-.86-4.21l-.59.16h0Z",
    "M43.14,69.19c1.2.09,2.49.07,3.82-.06h0l.5-.05-.07-.61c-1.45.16-2.89.2-4.21.11l-.05.61h.01Z",
    "M51.54,67.67l.16.59c1.46-.4,2.87-.91,4.06-1.49l-.27-.55c-1.16.56-2.53,1.06-3.95,1.45Z",
    "M38.89,68.3h0c-1.49-.57-2.7-1.41-3.6-2.47l.46-.39c.83.99,1.96,1.76,3.36,2.3l-.22.56Z",
    "M59.08,64.03l.36.5h0c1.16-.82,2.29-1.73,3.35-2.69l-.41-.45c-1.05.95-2.16,1.84-3.29,2.65h-.01Z",
    "M57.5,59.04c.69,1.14,1.32,2.41,1.86,3.78h0l.57-.22c-.55-1.4-1.19-2.7-1.9-3.87l-.52.31h-.01Z",
    "M65.35,58.38l.46.4c.93-1.05,1.83-2.18,2.68-3.35l-.5-.36c-.84,1.16-1.73,2.27-2.64,3.31Z",
    "M33.42,61.12c.02.24.05.49.09.71h0l.6-.09c-.04-.22-.07-.44-.09-.67-.03-.32-.05-.64-.05-.97,0-.85.1-1.67.34-2.45l-.59-.17c-.25.83-.36,1.71-.36,2.62,0,.35.02.69.06,1.02Z",
    "M51.75,53.05c1.17.73,2.26,1.62,3.23,2.65h0l.44-.42c-1.02-1.07-2.14-1.99-3.35-2.75l-.33.51h0Z",
    "M70.31,51.53l.53.31h0c.71-1.19,1.4-2.45,2.05-3.76l-.55-.27c-.64,1.29-1.33,2.54-2.03,3.72Z",
    "M35.96,53.72l.43.43c.94-.96,2.09-1.73,3.42-2.31l-.24-.56c-1.4.61-2.62,1.43-3.61,2.44Z",
    "M47.82,51.28l.13.04.18-.58-.13-.04c-1.32-.39-2.73-.55-4.2-.44l.04.61c1.4-.1,2.74.04,3.98.42h0Z",
  ],
  line2: [
    "M125.02,126.26l.08.61c1.43-.17,2.82-.65,4.13-1.41l-.31-.53c-1.25.72-2.56,1.17-3.9,1.33h0Z",
    "M116.83,124.66c1.28.84,2.61,1.46,3.93,1.84h0l.17-.59c-1.27-.36-2.53-.95-3.77-1.76l-.34.51h0Z",
    "M27.44,132.69l.31.53c1.22-.72,2.43-1.5,3.61-2.33l-.35-.5c-1.16.81-2.36,1.59-3.57,2.3Z",
    "M132.14,122.3l.46.4h0c.94-1.05,1.73-2.29,2.34-3.65l-.56-.25c-.59,1.31-1.35,2.49-2.25,3.5h.01Z",
    "M110.84,118.48c.79,1.22,1.69,2.37,2.67,3.39l.44-.42c-.95-.99-1.83-2.11-2.6-3.3l-.51.33Z",
    "M34.38,127.83l.39.47c1.09-.9,2.17-1.86,3.21-2.85l-.42-.44c-1.02.98-2.09,1.93-3.17,2.82h0Z",
    "M135.59,114.8l.6.1h0c.24-1.41.32-2.86.23-4.32l-.61.03c.09,1.4.01,2.81-.22,4.18h0Z",
    "M40.51,121.96l.46.41c.95-1.05,1.87-2.15,2.76-3.28l-.48-.38c-.87,1.11-1.78,2.21-2.73,3.25h-.01Z",
    "M107.7,110.47c.2,1.37.6,2.77,1.15,4.17l.57-.23c-.54-1.35-.92-2.71-1.12-4.03l-.61.09h.01Z",
    "M45.73,115.28l.51.34h0c.8-1.18,1.56-2.41,2.27-3.64l-.53-.3c-.71,1.23-1.46,2.44-2.25,3.61h0Z",
    "M134.24,102.35c.44,1.3.79,2.68,1.05,4.08h0l.6-.11c-.27-1.44-.63-2.84-1.07-4.17l-.58.19h0Z",
    "M107.61,106.15l.61.05h0c.11-1.37.34-2.76.67-4.16l-.6-.14c-.34,1.42-.57,2.86-.69,4.25h.01Z",
    "M49.96,107.92l.55.27h0c.62-1.28,1.21-2.6,1.73-3.92l-.57-.23c-.52,1.3-1.1,2.61-1.72,3.88h.01Z",
    "M130.39,94.89c.83,1.13,1.57,2.33,2.22,3.58l.54-.28c-.66-1.28-1.42-2.51-2.27-3.66,0,0-.49.36-.49.36Z",
    "M110.14,98h0l-.57-.21c.49-1.29,1.08-2.61,1.75-3.92l.54.28c-.66,1.29-1.23,2.59-1.72,3.86h0Z",
    "M53.11,100.04l.58.18h0c.43-1.37.82-2.75,1.15-4.13l-.6-.14c-.33,1.37-.71,2.74-1.14,4.09h.01Z",
    "M124.43,88.98c1.15.85,2.23,1.77,3.21,2.73h0l.43-.43c-1-.99-2.11-1.93-3.28-2.8l-.36.49h0Z",
    "M113.44,90.13l.52.32c.72-1.15,1.52-2.33,2.38-3.52l-.49-.36c-.87,1.19-1.67,2.39-2.4,3.55h0Z",
    "M117.06,84.85c1.33.55,2.61,1.17,3.81,1.84l.3-.53c-1.22-.68-2.53-1.3-3.87-1.87l-.23.56h0Z",
    "M51.24,92.52h0l-.08-.61c1.3-.19,2.63-.47,4.05-.88.17-1.14.3-2.3.4-3.45l.61.05c-.12,1.42-.29,2.85-.53,4.26l-.6-.1s.01-.06.01-.1c-1.35.38-2.61.65-3.86.82h0Z",
    "M42.65,92.05c1.39.38,2.82.61,4.27.68l.03-.61c-1.4-.07-2.8-.3-4.14-.67l-.16.59h0Z",
    "M59.51,90.19h0l-.23-.57c1.15-.45,2.4-.99,3.91-1.68l.25.56c-1.52.69-2.77,1.22-3.93,1.68h0Z",
    "M118.46,83.18l.47.38h0c.86-1.06,1.8-2.15,2.77-3.23l-.46-.4c-.98,1.09-1.92,2.19-2.79,3.25h.01Z",
    "M108.97,82.36c1.4.3,2.78.66,4.1,1.07l.18-.58c-1.33-.41-2.73-.77-4.16-1.08l-.13.6h.01Z",
    "M34.99,88.13c1.12.89,2.34,1.68,3.63,2.35l.28-.54c-1.25-.64-2.44-1.41-3.53-2.28l-.38.48h0Z",
    "M67.08,86.16l.25.56h0c1.25-.56,2.57-1.09,3.94-1.58l-.21-.58c-1.38.49-2.73,1.03-3.98,1.6Z",
    "M100.56,81.18c1.43.1,2.85.25,4.23.44h0l.08-.61c-1.39-.19-2.82-.34-4.27-.44l-.05.61h0Z",
    "M75.14,83.25l.17.58c1.34-.38,2.73-.73,4.12-1.04l-.13-.59c-1.41.31-2.81.66-4.16,1.05h0Z",
    "M92.06,81.1c1.42-.07,2.85-.1,4.25-.09h0v-.61c-1.41-.02-2.86.02-4.29.09l.03.61h.01Z",
    "M83.5,81.4l.1.6c1.38-.22,2.8-.41,4.21-.56l-.06-.61c-1.42.15-2.85.34-4.25.57h0Z",
    "M124.15,76.8l.48.38h0c.95-.98,1.96-1.98,2.98-2.95l-.42-.44c-1.03.98-2.05,1.98-3,2.97l-.05.04h0Z",
    "M55.79,83.35h.61c0-1.43-.05-2.87-.16-4.28l-.61.05c.11,1.39.16,2.82.15,4.24h.01Z",
    "M29.62,81.42c.61,1.28,1.38,2.52,2.31,3.66l.47-.38c-.89-1.11-1.64-2.3-2.23-3.54,0,0-.55.26-.55.26Z",
    "M130.35,70.88l.41.46h0c1.04-.92,2.13-1.85,3.24-2.76l-.39-.47c-1.11.92-2.22,1.84-3.26,2.77h0Z",
    "M54.12,70.81c.41,1.26.74,2.64,1,4.1h0l.6-.1c-.26-1.49-.6-2.89-1.02-4.18l-.58.18Z",
    "M28.11,75.93c.04.46.11.91.19,1.36l.6-.11c-.08-.43-.14-.87-.18-1.31-.09-.95-.1-1.91-.02-2.86l-.61-.05c-.08.98-.07,1.98.02,2.97Z",
    "M52.46,66.95h0c-.72-1.29-1.56-2.4-2.5-3.33l.43-.43c.98.97,1.86,2.13,2.6,3.47l-.53.29Z",
    "M29.02,68.73l.57.21c.49-1.33,1.16-2.56,2-3.64l-.48-.37c-.87,1.13-1.57,2.41-2.09,3.81h0Z",
    "M34.63,62.47h0l-.34-.51c1.21-.8,2.55-1.4,3.96-1.79l.16.58c-1.35.37-2.62.95-3.78,1.72h0Z",
    "M42.54,60.29c1.43.08,2.78.41,4.02.97l.25-.56c-1.31-.6-2.74-.94-4.24-1.02,0,0-.03.61-.03.61Z",
  ],
};

export const TraditionalPath: React.FC = () => {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getBaseTransforms = (width: number) => {
    if (width <= 1100)
      return [
        { scale: 0.28, x: 400, y: 80, rotate: -12 },
        { scale: 0.28, x: 385, y: 230, rotate: 5 },
        { scale: 0.23, x: 386, y: 370, rotate: 12 },
      ];
    if (width <= 1200)
      return [
        { scale: 0.28, x: 400, y: 80, rotate: -12 },
        { scale: 0.28, x: 385, y: 230, rotate: 5 },
        { scale: 0.23, x: 400, y: 400, rotate: 12 },
      ];
    if (width <= 1300)
      return [
        { scale: 0.28, x: 380, y: 80, rotate: -12 },
        { scale: 0.28, x: 385, y: 230, rotate: 5 },
        { scale: 0.23, x: 400, y: 410, rotate: 12 },
      ];
    if (width <= 1400)
      return [
        { scale: 0.28, x: 390, y: 80, rotate: -12 },
        { scale: 0.28, x: 385, y: 230, rotate: 5 },
        { scale: 0.23, x: 380, y: 410, rotate: 12 },
      ];
    if (width <= 1500)
      return [
        { scale: 0.28, x: 390, y: 80, rotate: -12 },
        { scale: 0.28, x: 385, y: 230, rotate: 5 },
        { scale: 0.23, x: 360, y: 410, rotate: 12 },
      ];
    if (width <= 1600)
      return [
        { scale: 0.28, x: 400, y: 80, rotate: -12 },
        { scale: 0.28, x: 385, y: 230, rotate: 5 },
        { scale: 0.23, x: 360, y: 410, rotate: 12 },
      ];
    if (width <= 1700)
      return [
        { scale: 0.28, x: 400, y: 80, rotate: -12 },
        { scale: 0.28, x: 385, y: 230, rotate: 5 },
        { scale: 0.23, x: 340, y: 410, rotate: 12 },
      ];
    if (width <= 1800)
      return [
        { scale: 0.28, x: 400, y: 80, rotate: -12 },
        { scale: 0.28, x: 370, y: 230, rotate: 5 },
        { scale: 0.23, x: 330, y: 410, rotate: 12 },
      ];
    return [
      { scale: 0.28, x: 385, y: 80, rotate: -12 },
      { scale: 0.28, x: 360, y: 230, rotate: 5 },
      { scale: 0.23, x: 310, y: 410, rotate: 12 },
    ];
  };

  const getTraditionalConnectorTransform = (index: number) => {
    const transform = getBaseTransforms(windowWidth)[index];
    return `scale(${transform.scale}) translate(${transform.x}, ${transform.y}) rotate(${transform.rotate})`;
  };

  return (
    <svg
      className="desktop-sm:block absolute inset-0 hidden h-full w-full"
      preserveAspectRatio="none"
      viewBox="0 0 156.26 149.2"
    >
      <g className="text-brand-moss-greens-90" opacity="0.4">
        {TRADITIONAL_CONNECTORS.line1.map((path, index) => (
          <g
            key={`line1-${index}`}
            transform={getTraditionalConnectorTransform(0)}
          >
            <path
              d={path}
              fill="currentColor"
              vectorEffect="non-scaling-stroke"
            />
          </g>
        ))}
        {TRADITIONAL_CONNECTORS.line1.map((path, index) => (
          <g
            key={`line2-${index}`}
            transform={getTraditionalConnectorTransform(1)}
          >
            <path
              d={path}
              fill="currentColor"
              vectorEffect="non-scaling-stroke"
            />
          </g>
        ))}
        {TRADITIONAL_CONNECTORS.line2.map((path, index) => (
          <g
            key={`line3-${index}`}
            transform={getTraditionalConnectorTransform(2)}
          >
            <path
              d={path}
              fill="currentColor"
              vectorEffect="non-scaling-stroke"
            />
          </g>
        ))}
      </g>
    </svg>
  );
};
