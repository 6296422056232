"use client";

import { TypeHero } from "@contentful/types";
import { customImageLoader } from "@yardzen/next-client-util";
import { analytics } from "@yardzen/next-segment";
import {
  Button,
  Carousel,
  CarouselContent,
  CarouselItem,
  cn,
  H1,
  Link,
  P,
  SegmentClickTypes,
} from "@yardzen/ui";
import AutoScroll from "embla-carousel-auto-scroll";
import Image from "next/image";
import { useRouter } from "next/navigation";

export function PhotoSliderCarousel({
  hero,
  segmentFlow,
  sectionName,
  titleClassName,
}: {
  hero: TypeHero<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: string;
  sectionName: string;
  titleClassName?: string;
}) {
  const router = useRouter();
  const { title, subtitle, images, displayOptions } = hero.fields;
  let carouselImages = images;
  if (carouselImages && carouselImages.length <= 6) {
    carouselImages = [...carouselImages, ...carouselImages];
  }

  const cta = hero.fields.cta;

  return (
    <div className="relative">
      <div className="mb-10 flex flex-col items-center text-center">
        {title && !displayOptions?.includes("suppress-title") && (
          <H1
            className={cn(
              "text-brand-primary-darker px-4 pt-12 text-4xl md:text-7xl lg:text-7xl",
              titleClassName,
            )}
          >
            {title}
          </H1>
        )}
        <P className="text-brand-moss-greens-90 w-[90%] pb-8 text-base md:min-w-[37%] md:max-w-[65%] md:text-xl">
          {subtitle}
        </P>
        <Carousel
          plugins={[
            AutoScroll({
              playOnInit: true,
              stopOnInteraction: false,
              speed: 0.5,
            }),
          ]}
          opts={{
            loop: true,
          }}
        >
          <CarouselContent className="-ml-1" aria-live="off">
            {carouselImages?.map((image, index) => {
              const imageUrl = image?.fields?.image?.fields?.file?.url;
              const alt = image?.fields?.image?.fields?.description;
              if (!imageUrl) {
                return null;
              }
              return (
                <CarouselItem
                  key={index}
                  className="basis-2/3 sm:basis-1/4 md:basis-1/4 lg:basis-1/6"
                >
                  <Link
                    variant="nav"
                    onClick={() => {
                      const url = hero.fields.cta?.fields.url;
                      if (url) {
                        analytics.trackClicked({
                          button_content: `${sectionName} image`,
                          flow_name: segmentFlow,
                          button_name: `${sectionName} image`,
                          click_type: SegmentClickTypes.CAROUSEL,
                        });
                        router.push(url);
                      }
                    }}
                  >
                    <Image
                      src={`https:${imageUrl}`}
                      alt={alt ?? ""}
                      height={500}
                      width={400}
                      className="h-full w-[22rem] rounded-[0.68981rem] object-cover"
                      loading="lazy"
                      loader={customImageLoader}
                      sizes="(max-width: 640px) 66vw,(max-width: 1024px) 25vw, 20vw"
                    />
                  </Link>
                </CarouselItem>
              );
            })}
          </CarouselContent>
        </Carousel>
        {cta && cta.fields.buttonText && cta.fields.url && (
          <div className="mt-10 flex flex-row justify-center">
            <Button
              variant={cta.fields.variant}
              className="w-full sm:w-auto"
              tracking={{
                button_content: cta?.fields.buttonText ?? "",
                button_name: `${sectionName} CTA`,
                flow_name: segmentFlow,
              }}
              onClick={() => {
                if (!cta?.fields.url) return;
                router.push(cta.fields.url);
              }}
            >
              {cta?.fields.buttonText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
