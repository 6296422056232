"use client";

import { TypeGenericSection } from "@contentful/types";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customImageLoader, SegmentFlows } from "@yardzen/next-client-util";
import { Button, cn, H1 } from "@yardzen/ui";
import Image from "next/image";
import { useRouter } from "next/navigation";

export function LiveBetterOutside({
  liveBetterOutside,
  className,
  segmentFlow,
  isDesignProfileQuizEnabled,
}: {
  liveBetterOutside: TypeGenericSection<"WITHOUT_UNRESOLVABLE_LINKS">;
  className?: string;
  segmentFlow: SegmentFlows;
  isDesignProfileQuizEnabled?: boolean;
}) {
  const router = useRouter();

  const { title, desktopBackground, CTAs } = liveBetterOutside.fields;

  const cta = CTAs?.[0];

  const ctaUrl = isDesignProfileQuizEnabled
    ? cta?.fields.challengerURL
    : cta?.fields.url;

  return (
    <div className="relative min-h-[480px]">
      <div className="relative aspect-[1445/634]">
        <div className="absolute inset-0 z-20 min-h-[480px] bg-black/50" />
        <Image
          src={desktopBackground?.fields.image?.fields.file?.url || ""}
          alt={title}
          fill
          className="z-10 min-h-[480px] object-cover"
          loader={customImageLoader}
        />
        <div className="absolute inset-0 z-30 flex min-h-[480px] items-center justify-center">
          <div
            className={cn("flex flex-col items-center gap-4 p-6", className)}
          >
            <H1 className="text-center text-[55px] text-white md:text-5xl">
              {title}
            </H1>
            <Button
              key={cta?.sys.id}
              className="w-48 border-white px-4 py-3 text-white md:w-auto"
              variant={cta?.fields.variant}
              endIcon={<FontAwesomeIcon icon={faArrowRight} />}
              tracking={{
                button_content: cta?.fields.buttonText,
                flow_name: segmentFlow,
                button_name: `Live Better Outside - ${cta?.fields.buttonText}`,
              }}
              onClick={() => {
                if (ctaUrl) router.push(ctaUrl);
              }}
            >
              {cta?.fields.buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
