"use client";

import { TypeBeforeAndAfterSection } from "@contentful/types";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { analytics } from "@yardzen/next-segment";
import { Button, cn, H1, H2, Large, UtilityCard } from "@yardzen/ui";
import { useRouter } from "next/navigation";
import { useState } from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
export function BeforeAndAfter({
  beforeAndAfter,
  segmentFlow,
}: {
  beforeAndAfter: TypeBeforeAndAfterSection<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: string;
}) {
  const [currentIndex, setCurrentIndex] = useState(0);

  function handleClick(index: number) {
    setCurrentIndex(index);
  }

  function parsePath(url: string | undefined) {
    if (!url) return "/before-after";
    const parsedUrl = new URL(url);
    return parsedUrl.pathname;
  }
  const beforeAndAfterEntries = beforeAndAfter.fields.beforeAfters;
  const beforeAndAfterCTA = beforeAndAfter.fields.buttonText;
  const beforeAndAfterLink = parsePath(beforeAndAfter.fields.url);
  const router = useRouter();
  const wordpressBaseUrl = process.env.NEXT_PUBLIC_WORDPRESS_BASE_URL;

  return (
    beforeAndAfterEntries && (
      <div className="md:px-8">
        <div className="flex w-full flex-col gap-8 overflow-hidden bg-white px-8 py-16 md:rounded-lg md:px-20 md:py-28">
          <H1>Explore Before & Afters</H1>
          <div className="flex w-full flex-row gap-4 overflow-y-scroll md:w-fit">
            {beforeAndAfterEntries?.map((entry, index) => (
              <div
                key={entry?.sys.id}
                onClick={async () => {
                  await analytics.trackClicked({
                    button_content: entry?.fields.title ?? "",
                    button_name: "Before and after tab selection",
                    flow_name: segmentFlow,
                    click_type: "button",
                  });
                  handleClick(index);
                }}
                className={cn(
                  "flex-none cursor-pointer rounded-lg p-4 text-base",
                  currentIndex === index &&
                    "bg-brand-tertiary-20 font-semibold",
                )}
              >
                <div>{entry?.fields.title}</div>
              </div>
            ))}
          </div>
          <div>
            <ReactCompareSlider
              className="relative mx-auto rounded-lg"
              itemOne={
                <div>
                  <ReactCompareSliderImage
                    src={
                      beforeAndAfterEntries[currentIndex]?.fields.before?.fields
                        .file?.url
                    }
                    alt="Image one"
                    loading="lazy"
                    sizes="(max-width: 768px) 100vw, 75vw"
                  />
                  <UtilityCard className="absolute bottom-4 left-4 w-fit bg-white px-6 py-4 text-center opacity-80 md:w-32">
                    <Large>Before</Large>
                  </UtilityCard>
                </div>
              }
              itemTwo={
                <div>
                  <ReactCompareSliderImage
                    src={
                      beforeAndAfterEntries[currentIndex]?.fields.after?.fields
                        .file?.url
                    }
                    alt="Image two"
                    loading="lazy"
                    sizes="(max-width: 768px) 100vw, 75vw"
                  />
                  <UtilityCard className="absolute bottom-4 right-4 w-fit bg-white px-6 py-4 text-center opacity-80 md:w-32">
                    <Large>After</Large>
                  </UtilityCard>
                </div>
              }
            />
          </div>
          <div className="flex w-full flex-col gap-4 sm:flex-row sm:gap-8">
            <div className="flex-1">
              <H1 className="text-2xl sm:text-4xl">
                {beforeAndAfterEntries[currentIndex]?.fields.eyebrow}
              </H1>
            </div>
            <div className="flex-1">
              <H2 className="text-base sm:text-3xl">
                {beforeAndAfterEntries[currentIndex]?.fields.description}
              </H2>
            </div>
          </div>
          <div className="flex w-full justify-center">
            <Button
              variant="outline"
              endIcon={
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-brand-primary-dark"
                />
              }
              onClick={() => {
                router.push(`${wordpressBaseUrl}${beforeAndAfterLink}`);
              }}
              className="text-brand-primary-dark border-brand-secondary-light"
              tracking={{
                button_content: beforeAndAfterCTA,
                button_name: "Before and After CTA button",
                flow_name: segmentFlow,
              }}
            >
              {beforeAndAfterCTA}
            </Button>
          </div>
        </div>
      </div>
    )
  );
}
